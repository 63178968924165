import React, { useState, useEffect, useContext } from "react"
import { Link, navigate } from "gatsby"
import { useMutation } from "@apollo/client"
import { NEW_USER } from "../mutations"
import { toast } from "react-toastify"
import client from "../apollo/client"
import { FilterContext } from "../context/FilterContext"

import textileLibraryLogo from "../images/textile_library_logo.png"

const IndexPage = () => {
  
  const { setDataProductsFilter, setTempDataFilter } = useContext(FilterContext)
  // Regex for email
  const emailRegex = RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  )
  /**
   * State for registration inputs
   */
  const [register, setRegister] = useState({
    firstname: "",
    lastname: "",
    companyName: "",
    email: "",
    messageError: {},
  })
  const { messageError } = register

  useEffect(() => {    
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("user_data")) {
        navigate("/orders")
      } else {
        navigate("/home")
      }
    } else {
      client.clearStore()
      localStorage.removeItem("token")
      localStorage.removeItem("user_data")
    }
    return () => {
      setDataProductsFilter([])
      setTempDataFilter([])
    }
  }, [])

  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data).forEach(val => {
      val === "" && (valid = false)
    })
    return valid
  }
  /**
   * Method that captures the value of each input
   * @param {*Parameter for input} e
   */
  const handleChange = e => {
    const { name, value } = e.target
    let errros = register.messageError
    switch (name) {
      case "firstname":
        errros.firstname = value === "" ? "This field is required" : ""
        break
      case "lastname":
        errros.lastname = value === "" ? "This field is required" : ""
        break
      case "email":
        errros.email =
          value === ""
            ? "This field is required"
            : "" || emailRegex.test(value)
            ? ""
            : "This is not a valid email"
        break
      default:
        break
    }
    setRegister(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = e => {
    e.target.reset()
    e.preventDefault()
    if (formValidate(register.messageError, register)) {
      handleRegisterUser()
    } else {
      toast.error("Please complete the form")
    }
  }

  const handleRegisterUser = () => {
    addUser({
      variables: {
        input: {
          data: {
            firstname: register.firstname,
            lastName: register.lastname,
            password: " ",
            username: register.email,
            email: register.email,
            companyName: register.companyName,
            role: 1,
            confirmed: true,
          },
        },
      },
    })
  }
  const [
    addUser,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(NEW_USER, {
    onCompleted: () => {
      toast.success("Your registration was completed successfully")
      setRegister({
        firstname: "",
        lastname: "",
        companyName: "",
        email: "",
        messageError: {},
      })
    },

    onError: error => {
      toast.error("The user is already registered")
    },
  })

  return (
    <>
     

      <div className="home-flex-content">
        <div className="home-page">
          <div className="home-page__text">
            <img src={textileLibraryLogo} alt="" />
            <h3>TEXTILE LIBRARY by Takihyo Coming Soon! </h3>
            <p>Pre-register for news and updates regarding the site!</p>
          </div>
          <form
            noValidate
            className="home-page__form"
            onSubmit={e => handleSubmit(e)}
          >
            <div className="u-position-relative">
              <input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleChange}
                className={messageError.email ? "border-error" : ""}
                placeholder="Email Address *"
              />
              {messageError.email && (
                <small className="field-error field-error-home">
                  {messageError.email}
                </small>
              )}
            </div>
            <div className="u-position-relative">
              <input
                type="text"
                name="companyName"
                onChange={handleChange}
                onBlur={handleChange}
                placeholder="Company/Brand Name"
              />
            </div>
            <div className="fields-group-name-lastname">
              <div className="u-position-relative">
                <input
                  type="text"
                  name="firstname"
                  onChange={handleChange}
                  onBlur={handleChange}
                  className={messageError.firstname ? "border-error" : ""}
                  placeholder="First Name*"
                />
                {messageError.firstname && (
                  <small className="field-error field-error-home">
                    {messageError.firstname}
                  </small>
                )}
              </div>
              <div className="u-position-relative">
                <input
                  type="text"
                  name="lastname"
                  onChange={handleChange}
                  onBlur={handleChange}
                  className={messageError.lastname ? "border-error" : ""}
                  placeholder="Last Name *"
                />
                {messageError.lastname && (
                  <small className="field-error field-error-home">
                    {messageError.lastname}
                  </small>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="u-text-uppercase btn btn-primary btn-submit"
            >
              Submit
            </button>
          </form>
          <p className="home-page__link">
            If you already know your username and password, log in on
            <Link to="/login"> TEXTILE LIBRARY</Link> .
          </p>
        </div>

        {/* <div className="home-page__links-footer">
          <Link to="/login">LOGIN</Link>
          <Link to="#">About</Link>
          <Link to="/terms-and-conditions">Terms & Condition</Link>
        </div> */}
      </div>
    </>
  )
}

export default IndexPage
